import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
import { required } from "@/i18n/i18n-validators";
// Composables
import useOptions from "@/composables/useOptions";
// Constants
import inputTypes from "@/constants/inputTypes";

export default function useCollection() {
  // Misc
  const { t } = useI18n();
  const store = useStore();

  // Computed
  const clientName = computed(() => store.getters["client/name"]);

  const { ALL_OPTIONS, defaultSystemOptions, getCertificates } = useOptions();

  // CONSTANTS
  const view_code = "Collections";
  const route = "teaching-collections";
  const endpoint = "teaching.collections";
  const searchFields = ["name"];
  const types = {
    version: inputTypes.TEXT,
    code: inputTypes.TEXT,
    author: inputTypes.TEXT,
    price: inputTypes.NUMBER,
    credits: inputTypes.NUMBER,
    requires_registration_approval: inputTypes.SELECT,
    requires_completion_approval: inputTypes.SELECT,
    certificate_template: inputTypes.SELECT,
    available_to: inputTypes.SELECT,
    assign_competencies_upon: inputTypes.SELECT,
    sys_admin_locked: inputTypes.SELECT,
    name: inputTypes.TEXT,
    details: inputTypes.TEXT,
    status: inputTypes.SELECT,
    tags: inputTypes.TAGS,
    date_activated: inputTypes.DATE,
    date_archived: inputTypes.DATE
  };
  const localizedFields = ["name", "details"];
  const headers = [
    {
      value: "image",
      class: "w-10"
    },
    {
      text: t("app.name"),
      value: "name"
    },
    {
      text: t("app.version"),
      value: "version"
    },
    {
      text: t("app.status"),
      value: "status",
      class: "w-40"
    },
    {
      text: t("app.actions"),
      value: "__ACTIONS",
      class: "w-56"
    }
  ];

  // DATA
  const value = ref({
    version: "1.0",
    code: "",
    author: clientName.value,
    credits: "",
    price: 0,
    requires_registration_approval: ALL_OPTIONS.NO.value,
    requires_completion_approval: ALL_OPTIONS.NO.value,
    certificate_template: "",
    assign_competencies_upon: ALL_OPTIONS.LEARNER_COMPLETION.value,
    available_to: ALL_OPTIONS.INTERNAL_LEARNERS.value,
    sys_admin_locked: ALL_OPTIONS.NO.value,
    name: "",
    details: "",
    status: ALL_OPTIONS.UNDER_REVIEW.value,
    image_file_id: "",
    tags: "",
    date_activated: "",
    date_archived: ""
  });
  const rules = ref({
    version: { required },
    code: {},
    author: {},
    credits: {},
    price: {},
    requires_registration_approval: { required },
    assign_competencies_upon: { required },
    requires_completion_approval: { required },
    certificate_template: {},
    available_to: { required },
    sys_admin_locked: { required },
    name: { required },
    details: {},
    status: { required },
    image_file_id: {},
    tags: {},
    date_activated: {},
    date_archived: {}
  });
  const options = ref({
    certificate_template: [],
    requires_registration_approval: defaultSystemOptions,
    requires_completion_approval: defaultSystemOptions,
    available_to: [
      ALL_OPTIONS.INTERNAL_LEARNERS,
      ALL_OPTIONS.EXTERNAL_LEARNERS
    ],
    sys_admin_locked: defaultSystemOptions,
    status: [
      ALL_OPTIONS.ACTIVE,
      ALL_OPTIONS.ARCHIVED,
      ALL_OPTIONS.UNDER_REVIEW
    ],
    assign_competencies_upon: [
      ALL_OPTIONS.LEARNER_COMPLETION,
      ALL_OPTIONS.COMPLETION_APPROVAL
    ]
  });

  // METHODS
  const getOptions = async () => {
    options.value.certificate_template = await getCertificates();

    return options.value;
  };

  return {
    headers,
    view_code,
    types,
    route,
    endpoint,
    searchFields,
    getOptions,
    value,
    rules,
    options,
    localizedFields
  };
}
